<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'LegacyDocumentsSettings', title: 'Налаштування документів' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_legacy_document_settings)"
        :modal="show_legacy_document_settings_dialog"
        @updateItemModal="legacyDocumentSettingsUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Налаштування документів (юр. облік)
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openLegacyDocumentSettingsCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити нове налаштування документів (юр. облік)</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              :single-select="true"
              :show-select="selectable"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1]

                }"
              class="custom-table custom-table-1"
              @click:row="onLegacyDocumentSettingsItemClick"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon>
              <v-icon size="26" class="mr-2" color="success">
                mdi-format-list-numbered
              </v-icon>
            </template>
            <template v-slot:item.document="{ item }">
              {{ getDocumentNameByType(item.document) }}
            </template>
            <template v-slot:item.create_date="{ item }">
              {{ item.create_date | formatDate }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import legacyDocumentsAPI from "@/utils/axios/legacy_documents"
import {ALERT_SHOW} from "@/store/actions/alert";
import {getDocumentNameByType} from "@/utils/accounting"

export default {
  props: ['selectable', 'model'],
  name: "LegacyDocumentSettingsView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  data() {
    return {
      selected: [],
      items: [],
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Документ', value: 'document' },
        { text: 'Створено', value: 'create_date' },
        { text: 'Автор', value: 'owner_name' }
      ],
      selected_legacy_document_settings: {},
      show_legacy_document_settings_dialog: false
    }
  },
  methods: {
    getDocumentNameByType,
    fetch() {
      legacyDocumentsAPI.get_all_setting()
          .then(response => response.data)
          .then(data => this.items = data)
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
   legacyDocumentSettingsUpdateItemModal() {
      this.show_legacy_document_settings_dialog = false
      this.selected_legacy_document_settings = {}

     this.fetch()
    },
    openLegacyDocumentSettingsCreateDialog() {
      this.selected_legacy_document_settings = {}
      this.show_legacy_document_settings_dialog = true
    },
    onLegacyDocumentSettingsItemClick(payload) {
      this.selected_legacy_document_settings = JSON.parse(JSON.stringify(payload))
      this.show_legacy_document_settings_dialog = true
    },
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>